<template>
  <div>
      <!--<home-top></home-top>-->
    <div class="mycontainer-main">
      <div class="caption"><span>{{ $t('header.campaign') }}</span></div>
      <div class="list display-flex " style="flex-wrap: wrap;">
        <a :href="item.url"
                      class="campaign-item flex-item display-flex flex-column decoration-none"  v-for="(item) in items" :key="genUniqueKey(item)"  >
          <div class="inner">
            <div class="flex1">
              <img :src="(item.image && item.image.desktop_thumbnail) ? item.image.desktop_thumbnail : $const.IMG_PIZZA_DEFAULT" />
            </div>

            <div class="info">
              <div class="flex1 bold t14" style="z-index: 1">
                <span class="date">{{convertUTCToClientTime(item.display_start).format('YYYY/MM/DD') + ' - ' + convertUTCToClientTime(item.display_end).format('YYYY/MM/DD')}}</span>
              </div>
              <div class="pt5 des" style="z-index: 1" v-if="!empty(item.description)"><nl2br v-if="item.description" tag="div" :text="item.description" /></div>

              <span class="more">{{ $t('explore_more') }}</span>
            </div>
          </div>

        </a>
      </div>
    </div>
    <home-footer></home-footer>
  </div>
</template>
<script>

export default {
  mounted () {
    this.initData()
    this.eventTrackingScreenLoad(this, {screen: {'name': 'campaign.list', 'type': 'campaign'}})
    const body = document.getElementsByTagName('body')[0]
    body.id = 'page-topic'
  },
  beforeCreate: function () {

  },
  beforeDestroy: function () {
    const body = document.getElementsByTagName('body')[0]
    body.id = ''
  },
  data () {
    return {
      items: []
    }
  },
  methods: {
    /**
     * Init data
     * */
    initData () {
      var vm = this
      let today = this.convertDateTimeToUTC()
      today = today.split(' ')
      let data = {'start': today[0], 'end': today[0], 'page': 1, 'limit': 10, 'sort_field': 'sequence', 'sort_type': 'asc'}
      vm.axios.apiTopic.getTopicList(data, function (response) {
        vm.items = response.data.data.items
      })
    }
  }
}

</script>
<style scoped>
  .app-bg{
    background: white !important;
  }
  .mycontainer-main{
    max-width: 1000px;
    margin: auto;
  }
  .campaign-item {
   width: calc(50% - 12px);
    margin: 6px;
  }
</style>
